<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('ticket_tracker')"
                        :isColumns="true"
                        :isFilter="false"
                        :otherButton="bulkApprovalObj"
                        @bulkApproval="bulkApprovalModal"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('ticket_tracker')"
                              :isColumns="true"
                              :otherButton="bulkApprovalObj"
                              bulkApproval="bulkApprovalModal"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                              :exportExcel="checkPermission('ticketeventapplication_downloadticket')" @exportExcel="downloadExcel">
                <b-row>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.name"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('surname')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.surname"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox v-model="datatable.queryParams.filter.gender"></gender-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('email')">
                            <b-form-input v-model="datatable.queryParams.filter.email"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox code="payment_status" v-model="datatable.queryParams.filter.status">
                            </parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('ticket_type')">
                            <parameter-selectbox code="ticket_types_id" v-model="datatable.queryParams.filter.ticket_event_type_id">
                            </parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('country')">
                            <country-selectbox v-model="datatable.queryParams.filter.country_id"></country-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <!-- Bulk Approval Form-->
            <CommonModal ref="bulkApproval" size="md" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('bulk_approval').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <bulk-approval v-if="formProcess=='bulkApproval'" @formSuccess="formSuccess"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//components
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

import qs from "qs";
//services
import TicketTrackerService from "@/services/TicketTrackerService";
import i18n from "@/plugins/i18n";
import BulkApproval from "@/modules/ticketTracker/pages/bulkApproval.vue";

export default {
    components: {
        BulkApproval,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ParameterSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('ticket_tracker')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-eye-line',
                                permission: "ticketeventapplication_ticketinformation",
                                callback: (row) => {
                                    this.$router.push('/ticket/tracker/detail/' + row.id)
                                },
                            },
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'surname',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('email'),
                        field: 'email',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('company_name'),
                        field: 'company',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('gender'),
                        field: 'gender',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('country'),
                        field: this.$i18n.locale=='tr' ? 'country_name':'country_name_en',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('national_id'),
                        field: 'national_id',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('date_of_birth'),
                        field: 'birthdate',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('mobile_number'),
                        field: 'phone',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('ticket_type'),
                        field: this.$i18n.locale=='tr' ? 'ticket_event_type_name':'ticket_event_type_name_en',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('amount'),
                        field: 'price',
                        hidden: false,
                        formatter: this.formatToCurrency
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        hidden: false,
                        formatFn:(row)=>{
                            if(row == 'waiting_payment'){
                                return this.$t('waiting_payment')
                            } else if(row == 'waiting') {
                                return this.$t('waiting')
                            } else if(row == 'approved') {
                                return this.$t('approved')
                            } else {
                                return row.status
                            }
                        }
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            bulkApprovalObj: {
                show: false,
                emit: 'bulkApproval',
                title: i18n.t('bulk_approval'),
            },
        }
    },
    mounted(){
        this.bulkApprovalObj.show = this.checkPermission('ticketeventapplication_bulkapprove')
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {}
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            TicketTrackerService.downloadExcel(config)
                .then(res => this._downloadFile(res, this.$t('ticket_tracker') + '.xlsx'))
                .catch(err => this.showErrors(err))
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return TicketTrackerService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;

                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formatToCurrency(value, key, item) {
            return this.$options.filters.formatToCurrency(value, item.currency);
        },
        formSuccess(){
            this.$refs.bulkApproval.$refs.commonModal.hide();
            this.formProcess = null;
            this.getRows()
        },
        bulkApprovalModal(){
            this.formProcess='bulkApproval';
            this.$refs.bulkApproval.$refs.commonModal.show();
        },
    }
}
</script>
