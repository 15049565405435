<template>
    <ValidationObserver ref="bulkApproval">
        <b-row>
            <b-col cols="12">
                <ValidationProvider name="id_list" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('id')">
                        <lined-textarea v-model="formData.id_list"
                                        :validate-error="errors[0]"
                                        :styles="{ height: '15em', resize: 'both' }"
                        >
                        </lined-textarea>
                    </b-form-group>
                </ValidationProvider>
            </b-col>

            <b-col rows="12" md="12" lg="12">
                <div class="d-flex justify-content-center mb-5">
                    <b-button variant="primary" @click="update">
                        {{ $t('save').toUpper() }}
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </ValidationObserver>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import TicketEventApplicationService from "@/services/TicketEventApplicationService";

export default {
    components: {
        LinedTextarea,

        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            formData: {}
        }
    },
    methods: {
        update() {
            const form = {
                id_list: this.formData.id_list
                    ? this.formData.id_list.split(/\n/).map(id => id.trim()).filter(id => id !== "")
                    : []
            };

            TicketEventApplicationService.ticketBulkApprove(form)
                .then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.$emit('formSuccess');
                })
                .catch(e => this.showErrors(e,this.$refs.bulkApproval))
        }
    }
}
</script>
